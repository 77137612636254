@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body, #root {
  padding: 0;
  margin: 0;
  height: 100%;
  width: 100%;
}

.thumbnail{
  height: 20vw;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}